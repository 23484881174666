import './Login/Login.scss';
import axios from 'axios';
import React, { useState, useEffect, Fragment } from 'react';
import SMILoginPresentation from './Login/LoginPresentation';
import LoginForm from './Login/Presentation';
import Presentation from './Login/Presentation';

export default (props) => {

    const [state, setState] = useState({
        userName: localStorage.getItem('userName') || '',
        password: '',
        rememberUsername: false,
        errorMessage: '',
        inProgress: false
    });

    //useEffect(() => {
    //    var rawUser = localStorage.getItem('user');
    //    if (rawUser) {
    //        localStorage.clear();
    //        window.location.reload();
    //    }        
    //}, []);

    const getLoginToken = () => {
        axios.post('/api/Login', { userName:state.userName, password: state.password })
            .then((response) => {
                localStorage.setItem('user', JSON.stringify(response.data));
                localStorage.setItem('token', JSON.stringify(response.data));
                if (props.onLoginSuccess) props.onLoginSuccess();
                else window.location.reload();
            }).catch((err) => {
                console.log('Error');
            });
    }

    const handleInputChange = (event) => setState({ ...state, [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value })


    const onSubmit = e => {
        setState(_state => ({ ..._state, inProgress: true }));

        axios.get('/api/Login').then(response => {
            const guid = getGuid();
            const xmlPublicKey = response.data;
            var authenInfo = { id: guid, pubkey: xmlPublicKey };
            axios.post('https://api.mapstrat.com/api/ClientPubKey', authenInfo).then(response => {
                axios.post('https://api.mapstrat.com/api/SMIAuthen',
                    { userName: state.userName, password: state.password, keyID: guid })
                    .then(response => {
                        console.log(response);
                        getLoginToken();
                    })
                    .catch(err => {
                        console.log('error');
                        setState(_state => ({ ..._state, errorMessage: 'Incorrect username and/or password' }));
                    });
            }).catch(err => console.log('error'));
        }).catch((err) => setState({ isSmiLogin: false }));
    }
    return (<Fragment>
        <Presentation onSubmit={onSubmit} data={state} handleInputChange={handleInputChange} />
    </Fragment>
    );


};

//GUID generation function from https://gist.github.com/jed/982883
export function getGuid(a) { return a ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16) : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, getGuid) }

