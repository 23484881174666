import './Devices.scss';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Presentation from './Presentation';


export default () => {    
    const [state, setState] = useState({
        devices: [],
        vehicles: [],
        hardwares:[],
        isLoading: false
    });
    useEffect(() => {
        let vehiclePromise = axios.get('/api/vehicles');
        let hardwarePromise = axios.get('/api/SupportHardware');
        axios.all([vehiclePromise, hardwarePromise]).then(axios.spread((...response) => {
            let _vehicles = response[0].data;
            let _hardwares = response[1].data;
            _vehicles.unshift({ vehicleId: 0, label: 'Select Vehicle' });
            _hardwares.unshift({ hardwareId: 0, registrationNumber: 'Select Hardware'});
            setState(_state => ({ ..._state, vehicles: _vehicles, hardwares: _hardwares }));
        })).catch(err => console.log(err));
    }, []);

    return (
        <Presentation data={state} />
    );
}