import React, { Fragment } from 'react';
import { Route } from 'react-router';
import { Home } from './Home';
import Login from './Login';
import Client from './Client/Clients';
import Devices from './Devices/Devices';
import CommandHistory from './CommandHistory/CommandHistory';
import Commands from './Commands/Commands';
import Configure from './Configure/Configure';
import CommandType from './CommandType/CommandType';

export default () => {

    return (
        <Fragment>
            <Route exact path='/' component={Home} />
            <Route exact path='/login' component={Login} />            
            <Route exact path='/home' component={Home} />
            <Route path='/devices' component={Devices} />
            <Route path='/commands' component={Commands} />
            <Route path='/command-type' component={CommandType} />
            <Route path='/history' component={CommandHistory} />
            <Route path='/configure' component={Configure} />           
        </Fragment>
    );
}