import React, { Component } from 'react';
import SMIGridPresentation from './SMIGridPresentation';
import { process } from '@progress/kendo-data-query';
import SMIGridRenderers from './SMIGridRenderers';
import axios from 'axios';
import { convertDateStringsToDates, convertDatesToStrings } from '../Utils/DateUtil';
import { kendoAlert } from '../Utils/DialogUtil';
import { setTimeout } from 'timers';

import './SMIGrid.scss';
import { Prompt } from 'react-router-dom';

export default class SMIGrid extends Component {

    state = {
        data: [],
        showSavedMessage: false,
        filter: null,
        sort: null,
        columns: [],
        dataState: { sort: this.props.sort, group: this.props.group, skip: 0, take: this.props.pageable ? this.props.itemsPerPage : undefined },
        editField: undefined,
        selectedItems: new Set(),
        pageNumber: 1,
        loading: false,
        result: null,
        searchQuery: '',
        showErrorMessage: false,
        errorMessage: '',
        isSaving : false
    };

    pristineData: [];
    deletedItems = [];
    columnsByField = {};
    dummyId = -1;
    expandToggledFields = new Set();
    gridRef = React.createRef();
    excelExportRef = React.createRef();

    constructor(props) {
        super(props);

        this.renderers = new SMIGridRenderers(this.enterEdit.bind(this), this.exitEdit.bind(this), this.itemChange.bind(this), props.children, props.rowColor, 'inEdit', this.state, props, this.onRowClick.bind(this));
    }

    enterEdit(dataItem, field) {
        if (dataItem.inEdit && field === this.state.editField) {
            return;
        }
        if (!this.exitEdit()) return;
        dataItem.inEdit = field;
        this.setState({
            editField: field,
            data: this.state.data
        });
        const column = this.columnsByField[field];
        if (column && column.props.onEnterEdit) column.props.onEnterEdit(dataItem);
    }

    exitEdit() {
        if (this.state.editField) {
            //If the current column being edited has a preventExitEdit prop, call it to check if it's okay to allow the user to stop editing the cell
            const column = this.columnsByField[this.state.editField];
            if (column) {
                const editItemArr = this.state.data.filter((d) => !!d.inEdit);
                if (column.props.preventExitEdit) {
                    if (editItemArr.length > 0) {
                        editItemArr[0].preventExitEditMessage = column.props.preventExitEdit(editItemArr[0]);
                        if (editItemArr[0].preventExitEditMessage) {
                            this.forceUpdate();
                            return false;
                        }
                    }
                }
                if (column.props.onExitEdit && editItemArr.length > 0) column.props.onExitEdit(editItemArr[0]);
            }
        }
        this.state.data.forEach((d) => { d.inEdit = undefined; d.preventExitEditMessage = undefined; });
        this.setState({
            data: this.state.data,
            editField: undefined
        });
        return true;
    }

    deepCopy(aObject) {
        if (!aObject) {
            return aObject;
        }

        var bObject, v, k;
        bObject = Array.isArray(aObject) ? [] : {};
        for (k in aObject) {
            v = aObject[k];
            bObject[k] = (typeof v === "object" && !(v instanceof Date)) ? this.deepCopy(v) : v;
        }
        return bObject;
    }

    loadingOn = () => this.setState({ loading: true });
    loadingOff = () => this.setState({ loading: false });

    loadData = () => {
        if (!this.props.crudUrl) return;
        this.setState({ loading: true });
        axios.get(this.props.crudUrl).then(response => {
            let data = response.data;
            convertDateStringsToDates(data);            
            this.pristineData = this.deepCopy(data);
            this.setState({ data: data }, () => {
                if (this.state.selectedItems && this.state.selectedItems.size > 0) {
                    this.updateSelectedItems();
                }
            });
            if (this.props.onDataLoaded) this.props.onDataLoaded(data);
        })
            .catch(error => {
                console.log(error);
                kendoAlert('Error loading data');
            })
            .then(() => this.setState({ loading: false }));
    }

    updateSelectedItems = () => {
        let isSelectedItemChanged = false;
        let updatedSelectedItems = new Set();
        let idField = this.gridRef.current.props.idField || 'id';
        let firstSelectedItem;

        this.state.selectedItems.forEach((item) => {
            let updatedItem = this.state.data.find(d => d[idField] && d[idField] === item[idField]);
            if (updatedItem) {
                updatedSelectedItems.add(updatedItem);                
                isSelectedItemChanged = true;
                if (!firstSelectedItem) firstSelectedItem = updatedItem;
            }
        });

        if (isSelectedItemChanged) {
            this.setState({ selectedItems: updatedSelectedItems }, () => {
                this.updateDataResult(this.state.dataState);
                //Call the custom onRowClick method so that clients get the updated selectedItems
                if (this.props.onRowClick) this.props.onRowClick({ dataItem: this.state.selectedItems.values().next().value });
            });
        }
        
    }

    setGridFilter = (_filter) => {
        let newDataState = Object.assign({}, this.state.dataState);
        newDataState.filter = _filter;
        this.setState({ dataState: newDataState }, () => {
            this.updateDataResult(this.state.dataState);
        });
    }

    getDummyId() {
        this.dummyId--;
        return this.dummyId;
    }

    flashSavedMessage = () => {
        this.setState({ showSavedMessage: true });
        setTimeout(() => this.setState({ showSavedMessage: false }), 4000);
    }

    flashErrorMessage = (errStr) => {
        let message = 'Failed';
        if (errStr.message)
            message = errStr.message;
        else if (errStr.ErrorMessage)
            message = "Failed";
        else
            message = errStr;

        this.setState({ showErrorMessage: true, errorMessage: message || 'Failed' });
        setTimeout(() => this.setState({ showErrorMessage: false }), 4000);
    }

    saveData = () => {
        this.exitEdit();
        if (this.validate()) {
            //Need to make two requests. One to save new/updated data items, and one to delete data items
            const dirtyData = this.state.data.filter(d => d.dirty).map(d => Object.assign({}, d));
            convertDatesToStrings(dirtyData);
            if (dirtyData.length > 0 || this.deletedItems.length > 0) {

                const saveRequest = dirtyData.length > 0 ? (this.props.onSave ? this.props.onSave(dirtyData) : axios.post(this.props.postUrl || this.props.crudUrl, dirtyData).then(response => {

                    if (response && response.data) {
                        var dataObj = response.data[0];
                        if (dataObj && dataObj.message && dataObj.message != '' && dataObj.message !== "") {                            
                            return Promise.reject(dataObj.message);
                        }
                        else {
                            return Promise.resolve();
                        }
                    }
                })) : Promise.resolve();

                const deleteRequest = this.deletedItems.length > 0 ? (this.props.onDelete ? this.props.onDelete(this.deletedItems) : axios.delete(this.props.deleteUrl || this.props.crudUrl, { data: this.deletedItems })).then(response => this.deletedItems = []) : Promise.resolve();
                this.setState({ loading: true, isSaving : true });
                Promise.all([saveRequest, deleteRequest]).then(() => {
                    this.loadData();
                    this.deletedItems = [];
                    this.setPageDirtyValue(false);
                    this.flashSavedMessage();
                    if (this.props.onSaveComplete && typeof this.props.onSaveComplete === 'function') this.props.onSaveComplete();
                }).catch(error => {
                    
                    let err = error.response && error.response.data ? error.response.data : {};
                    let errorMessage = err.ErrorMessage || 'Unable to save due to an error';
                    console.log(errorMessage);
                    if (errorMessage.toLowerCase().search('reference') !== -1) {
                        if (errorMessage.toLowerCase().search('delete') !== -1)
                            errorMessage = 'Delete failed due to reference error!';
                        else
                            errorMessage = 'Save failed due to reference error!';
                    }
                    this.flashErrorMessage(errorMessage);
                })
                    .then(() => this.setState({ loading: false, isSaving: false }));
            }
        }
        else this.setState({ data: this.state.data });
    }

    cancelChanges = () => {
        this.deletedItems = [];
        this.setState({ data: this.deepCopy(this.pristineData) }, () => {
            if (this.props.onCancelChanges) this.props.onCancelChanges();
        });
        this.setPageDirtyValue(false);
    }

    getColumnListForColumnMenu() {
        let columns = [];
        this.props.children.forEach((c) => {
            if (!c) return;
            //Exclude columns with fields starting with "_" from the column menu as these are action columns (e.g. the delete button)
            if (c.props.field && c.props.field.indexOf('_') !== 0) {
                let isColumnShow = !c.props.hidden;
                //If a column is selected to show, then that column show state should be selected from the previous state
                if (this.state.columns) {
                    let prevColumnState = this.state.columns.find(col => col.field === c.props.field);
                    if (prevColumnState)
                        isColumnShow = prevColumnState.show;
                }
                columns.push({ field: c.props.field, title: c.props.title, show: isColumnShow });
            }
                
        });
        return columns;
    }

    componentDidMount() {
        if (this.props.crudUrl) this.loadData();
        else if (this.props.data) {
            this.pristineData = this.deepCopy(this.props.data);
            this.setState({ data: this.props.data });
        }
        this.columnsByField = this.getColumnsByField();
        this.setState({ columns: this.getColumnListForColumnMenu() });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.crudUrl !== this.props.crudUrl) {
            if (this.props.crudUrl) this.loadData();
            else this.setState({ data: [] });
        }
        //Update columns and renderers incase column props changed
        let columnsChanged = false;
        if (this.props.children.length !== prevProps.children.length) columnsChanged = true;
        else {
            for (let i = 0; i < this.props.children.length; i++) {
                if (this.props.children[i] !== prevProps.children[i]) {
                    columnsChanged = true;
                    break;
                }
            }
        }
        if (columnsChanged) {
            this.columnsByField = this.getColumnsByField();
            this.renderers = new SMIGridRenderers(this.enterEdit.bind(this), this.exitEdit.bind(this), this.itemChange.bind(this), this.props.children, this.props.rowColor, 'inEdit', this.state, this.props, this.onRowClick.bind(this));
            this.setState({ columns: this.getColumnListForColumnMenu() });
        }
        if (prevProps.data !== this.props.data) {
            this.pristineData = this.deepCopy(this.props.data);
            this.setState({ data: this.props.data });
        }
        if (prevState.data !== this.state.data || prevState.searchQuery !== this.state.searchQuery) {
            this.updateDataResult(this.state.dataState);
        }
        if (prevProps.itemsPerPage !== this.props.itemsPerPage || prevProps.pageable !== this.props.pageable) {
            const newDataState = Object.assign({}, this.state.dataState);
            newDataState.take = this.props.pageable ? this.props.itemsPerPage : undefined;
            newDataState.skip = 0;
            this.setState({ pageNumber: 1, dataState: newDataState });
            this.updateDataResult(newDataState);
        }
    }

    //React prompt cannot prevent page refresh . So added onbeforeunload window function
    componentWillMount() {
        onbeforeunload = event => {
            const dirtyData = this.state.data.filter(d => d.dirty).map(d => Object.assign({}, d));
            if (dirtyData && dirtyData.length > 0) {
                event.preventDefault();
                event.returnValue = 'Data going to loss';
            }
        }
    }

    componentWillUnmount() {
        onbeforeunload = null;
    }

    getColumnsByField() {
        let columnsByField = {};
        this.props.children.forEach((c) => {
            if (!c) return;
            //Exclude columns with fields starting with "_" from the column menu as these are action columns (e.g. the delete button)
            if (c.props.field) columnsByField[c.props.field] = c;
        });
        return columnsByField;
    }

    getDefaultValues() {
        let defaults = {};
        this.props.children.filter(c => c && c.props.field).forEach(c => defaults[c.props.field] = (c.props.defaultValue === undefined ? '' : c.props.defaultValue));
        return defaults;
    }

    onColumnsSubmit = (columnsState) => {
        this.setState({
            columns: columnsState
        }, () => {
            if (this.props.onColumnsSubmit && typeof this.props.onColumnsSubmit === 'function')
                this.props.onColumnsSubmit(columnsState);
        });
    }

    addNew = () => {
        const defaults = this.getDefaultValues();
        let newItem = Object.assign({}, defaults);
        if (this.props.idField) newItem[this.props.idField] = this.getDummyId();
        else newItem.id = this.getDummyId();
        let newData = this.state.data.slice(0);
        newItem.dirty = true;
        newData.unshift(newItem);
        this.setState({ data: newData });

        //Automatically start editing the first editable column in the grid for the new item
        const firstEditableField = this.getFirstEditableColumnField();
        setTimeout(() => this.enterEdit(newItem, firstEditableField), 10);

        if (this.props.onAddNew) this.props.onAddNew(newItem);
        this.selectChange(newItem);
        return newItem;
    }

    addNewDataItem = newItem => {
        const defaults = this.getDefaultValues();
        let newData = this.state.data.slice(0);
        newData.unshift(newItem);
        this.setState({ data: newData });        
        return newItem;
    }

    removeDataItem = dataItem => {
        let data = this.state.data.slice(0);
        let index = data.findIndex(item => item.id == dataItem.id);
        data.splice(index, 1);
        this.setState({ data: data });
    }

    expandChange = (event) => {
        event.dataItem[event.target.props.expandField] = event.value;
        if (event.value !== !this.props.collapseGroups) this.expandToggledFields.add(event.dataItem.value);
        else this.expandToggledFields.delete(event.dataItem.value);
        this.setState({ result: Object.assign({}, this.state.result), dataState: this.state.dataState });
    }

    setPageDirtyValue(value) {
        if (!this.props.disableNavigationWarning && window.self !== window.top && window.parent && typeof window.parent.setChildPageDirty === 'function') {
            window.parent.setChildPageDirty(value);
        }
    }

    searchQuerySubmitted = (searchQuery) => {
        this.setState({ searchQuery: searchQuery });
    }

    validate() {
        let validationPassed = true;
        if (this.state.data) {
            for (let i = 0; i < this.props.children.length; i++) {
                if (this.props.children[i] && this.props.children[i].props.field && this.props.children[i].props.validationRules) {
                    const field = this.props.children[i].props.field;
                    this.props.children[i].props.validationRules.forEach((rule) => {
                        this.state.data.forEach((item) => {
                            const result = rule(item[field], item, field, this.state.data);
                            if (result !== true) {
                                //Validation failed. Mark this item as having an error
                                validationPassed = false;
                                if (!item.errorFields) item.errorFields = {};
                                if (!item.errorFields[field]) item.errorFields[field] = typeof result === 'string' ? result : true; //If validation function returned a string, store it in errorFields so it can be displayed as a tooltip
                            }
                        });
                    });
                }
            }
        }
        return validationPassed;
    }

    getFirstEditableColumnField() {
        for (let i = 0; i < this.props.children.length; i++) {
            if (this.props.children[i] && this.props.children[i].props.editable && this.props.children[i].props.field && !this.props.children[i].props.hidden)
                return this.props.children[i].props.field;
        }
        return null;
    }

    itemChange = (event) => {
        let updatedData = this.state.data.slice(0);
        if (event.field === '_delete') {
            //Delete button was pressed, delete the item
            this.deletedItems.push(event.dataItem);
            for (let i = 0; i < updatedData.length; i++) {
                if (this.state.data[i] === event.dataItem) {
                    updatedData.splice(i, 1);
                    break;
                }
            }
            this.setPageDirtyValue(true);
        }
        else if (event.dataItem[event.field] !== event.value) {
            //If the new value in the change event is different from the current value, change the field value to the new value
            this.setValue(event.dataItem, event.field, event.value);
        }
        //Update state so that grid cells will refresh
        this.setState({ data: updatedData }, () => {
            if (this.props.onItemChange && typeof this.props.onItemChange === 'function')
                this.props.onItemChange(event);
        });        
    }

    setValue = (dataItem, field, value) => {
        const column = this.columnsByField[field];
        const oldValue = dataItem[field];
        dataItem[field] = value;
        dataItem.dirty = true;
        if (!dataItem.dirtyFields) dataItem.dirtyFields = {};
        dataItem.dirtyFields[field] = true;
        this.setPageDirtyValue(true);
        //Clear any errors and then validate again on next save
        dataItem.errorFields = [];
        dataItem.errorMessages = {};
        if (column && column.props.onChange) column.props.onChange(dataItem, field, oldValue, value, this.setValue.bind(this));

    }

    navigateOnTab = (e) => {
        if (e.keyCode === 9) {
            //Tab key pressed, switch to editing the next cell over
            let editingItem = null;
            for (let i = 0; i < this.state.data.length; i++) {
                if (this.state.data[i].inEdit) {
                    editingItem = this.state.data[i];
                    break;
                }
            }
            if (editingItem) {
                e.preventDefault();
                const editableColumns = this.props.children.filter(c => c && !!c.props.field && c.props.editable !== false);
                let currentColumnIndex = -1;
                for (let i = 0; i < editableColumns.length; i++) {
                    if (editableColumns[i].props.field === editingItem.inEdit) {
                        currentColumnIndex = i;
                        break;
                    }
                }
                if (currentColumnIndex !== -1 && editableColumns.length > currentColumnIndex + 1)
                    this.enterEdit(editingItem, editableColumns[currentColumnIndex + 1].props.field);
                else
                    this.exitEdit();
            }
            return false;
        }
    }

    export = (exportAll) => {
        let data = [];
        //if there is paging enable, export is exporting only current page data. So pass exportAll if you want export all data
        if (exportAll)
            data = this.deepCopy(process(this.state.data, { }).data);
        else
            data = this.deepCopy(process(this.state.data, this.state.dataState).data);
        //Apply templates to data so that they will appear in the exported excel file the same way they appear in the grid
        data.forEach((d) => {
            for (let field in this.columnsByField) {
                const template = this.columnsByField[field].props.customTemplate;
                if (template && typeof template === 'function') d[field] = template(d, field);
            }
        });
        const excelColumns = this.gridRef.current.columns.filter(c => c.field.indexOf('_') !== 0);
        this.excelExportRef.current.save(data, excelColumns);
    }

    pageChange = (event) => {
        this.setState({
            pageNumber: Math.floor(event.page.skip / this.props.itemsPerPage) + 1
        });
    }

    isGroup(item) {
        return item.aggregates && item.items;
    }

    isDirty = () => {
        const dirtyData = this.state.data.filter(d => d.dirty);
        return dirtyData? dirtyData.length > 0 : false;
    }

    performSearchFilter = (data, searchQuery) => {
        if (!searchQuery) return data;
        const searchQueryLower = searchQuery.toLowerCase();
        return data.filter(dataItem => {
            for (let field in this.columnsByField) {
                if (field.indexOf('_') === 0) continue; // Skip columns starting with _ since these are buttons.
                let dataValue = dataItem[field];
                if (this.columnsByField[field].props.customTemplate) {
                    dataValue = this.columnsByField[field].props.customTemplate(dataItem, field, data);
                }
                if (dataValue && dataValue.toString().toLowerCase().indexOf(searchQueryLower) !== -1) return true;
            }
            return false;
        });
    }

    updateDataItem = (newDataItem, field = 'id') => {
        let _data = this.deepCopy(this.state.data);
        for (let i = 0; i < _data.length; i++) {
            if (_data[i][field] === newDataItem[field]) {
                _data[i] = newDataItem;
                break;
            }
        }        
        if (_data) {            
            this.setState({ data: _data }, () => {
                this.selectChange(newDataItem);
            });
        }
    }

    updateDataResult = (dataState, selectedItems) => {
        if (!selectedItems) selectedItems = this.state.selectedItems;
        let data = this.state.data;
        if (this.props.searchable) data = this.performSearchFilter(data, this.state.searchQuery);
        if (this.props.selectMode) data.forEach(item => { item[this.props.selectedField] = selectedItems.has(item); });
        const result = process(data, dataState);
        if (this.props.groupable) {
            result.data.forEach(item => {
                //If this item is grouping, set its expanded state based on the previously saved value or the default value based on the collapseGroups prop
                if (this.isGroup(item) && item._expanded === undefined) {

                    if (this.expandToggledFields.has(item.value)) item._expanded = !!this.props.collapseGroups;
                    else item._expanded = !this.props.collapseGroups;
                }
            });
        }
        if (this.props.onDataUpdate && typeof this.props.onDataUpdate === 'function')
            this.props.onDataUpdate(result);

        try {
            //Include new data item with data result so that the user can see what new data s/he is added
            let id = this.props.idField || 'id';
            if (this.state.data.find(d => d[id] <= 0)) {
                let allNewData = this.state.data.filter(d => d[id] <= 0);                
                if (allNewData)
                    allNewData.forEach(newData => {
                        if (!result.data.find(d => d[id] === newData[id]))
                            result.data.unshift(newData);
                    });
            }
        } catch (e) {            
        }
        

        this.setState({ result: result, dataState: dataState });
    }

    dataStateChange = (event) => {
        this.updateDataResult(event.dataState);
        if (this.props.onDataStateChange) this.props.onDataStateChange(event.dataState);
    }

    selectChange = (selectedItems) => {
        selectedItems = new Set(Array.isArray(selectedItems) ? selectedItems : [selectedItems]);
        this.setState({ selectedItems: selectedItems }, () => {
            this.updateDataResult(this.state.dataState, selectedItems);
            if (this.props.onSelectChange) setTimeout(() => this.props.onSelectChange(Array.from(selectedItems)));
        });
    }

    onRowClick = (event) => {
        if (!this.props.selectMode) return;
        else if (this.props.selectMode === 'single') {
            if (!this.state.selectedItems.has(event.dataItem)) this.selectChange([event.dataItem]);
        }
        else if (this.props.selectMode === 'multiple') {
            const data = this.state.result.data || this.state.result;
            let last = this.lastSelectedIndex;
            const current = data.findIndex(dataItem => dataItem === event.dataItem);

            if (!event.nativeEvent || !event.nativeEvent.shiftKey || (!last && last !== 0)) {
                this.lastSelectedIndex = last = current;
            }
            else {
                if (window.getSelection) { window.getSelection().removeAllRanges(); }
                else if (document.selection) { document.selection.empty(); }
            }

            const selectedItems = this.state.selectedItems;
            //Check if the selected items are still exist in dataset
            //if you remove any dataitem from the grid, it might not update
            //selecetdItems list
            let arrSelectedItems = Array.from(selectedItems);
            for (let i = 0; i < arrSelectedItems.length; i++) {
                let item = data.findIndex(dataItem => dataItem === arrSelectedItems[i]);
                if (item < 0) selectedItems.delete(arrSelectedItems[i]);
            }

            if (!event.nativeEvent || !event.nativeEvent.ctrlKey) {
                data.forEach(item => { item.selected = false; selectedItems.delete(item); });
            }

            const select = !event.dataItem.selected;
            for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
                data[i].selected = select;
                if (this.state.result.data[i].selected) selectedItems.add(data[i]);
                else selectedItems.delete(data[i]);
            }

            this.selectChange(Array.from(selectedItems));
        }

        //If the currenct selected column is non editable then exit the edit
        let column = this.columnsByField[event.dataItem.inEdit];        
        if (!column)
            this.exitEdit();

        if (this.props.onRowClick) this.props.onRowClick(event);
    }

    render() {
        const gridProps = Object.assign({}, this.props);
        const children = this.props.children;
        delete gridProps.children;    
        
        const dirtyData = this.state.data? this.state.data.filter(d => d.dirty).map(d => Object.assign({}, d)) : [];

        // We include a div wrapper so that we can get the component instance from the DOM node (see getComponentFromDomNode() in ReactUtils.js)
        return (
            <div className="smi-grid">
                <Prompt when={dirtyData && dirtyData.length > 0} message="Please save or cancel your change before you navigate." />
                <SMIGridPresentation
                    data={this.state.result}
                    onItemChange={this.itemChange}
                    onExpandChange={this.expandChange}
                    onSelectChange={this.selectChange}
                    onDataStateChange={this.dataStateChange}
                    onColumnsSubmit={this.onColumnsSubmit}
                    onRowClick={this.onRowClick}
                    columns={this.state.columns}
                    onAdd={this.addNew}
                    onSave={this.props.onSaveData || this.saveData}
                    onCancelChanges={this.cancelChanges}
                    onSearch={this.searchQuerySubmitted}
                    onExcelExport={this.export}
                    isLoading={this.state.loading}
                    saving={this.state.isSaving}
                    showSavedMessage={this.state.showSavedMessage}
                    showErrorMessage={this.state.showErrorMessage}
                    errorMessage={this.state.errorMessage}
                    dataState={this.state.dataState}
                    excelExportRef={this.excelExportRef}
                    gridRef={this.gridRef}
                    gridProps={gridProps}
                    renderers={this.renderers}
                    onKeyDown={this.navigateOnTab}>
                    {children}
                </SMIGridPresentation>
            </div>
        );

    }
}

SMIGrid.defaultProps = {
    filterable: true,
    sortable: true,
    editable: true,
    groupable: false,
    showColumnMenu: false,
    searchable: false,
    resizable: true,
    selectedField: '_isSelected',
    selectMode: 'single',
    addSaveEvent: true
}