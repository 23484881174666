
import React, { useState, useEffect, useRef } from 'react';
import PageWrapper from '../PageWrapper/PageWrapper';
import SMIGrid from '../SMIGrid/SMIGrid';
import { GridColumn as Column } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import axios from 'axios';
import * as signalR from '@microsoft/signalr';


function* CommandGen(commands) {
    let index = 0;
    while (index < commands.length) {
        yield commands[index];
        index++;
    }
}


export default (props) => {
    const gridRef = useRef();
    const currentExecutingCommand = useRef();
    const commandGen = useRef();
    const commandExpireTimer = useRef();

    const [state, setState] = useState({
        isInProgress: false,
        genValue: 0,
        commands: null,
        isConfigure: props.data ? props.data.isConfigure : false,
        dataItem: props.data ? props.data.dataItem : null,
        isComplete: false,
        currentExecutingCommand: null,
        commandGen: null,
        commandExpireTimer: null,
        signalRConnection: null
    });

    useEffect(() => {
        openSignRConnction();
    }, [])
    

    const openSignRConnction = () => {
        //Use SignalR to listen for emergencies acknowledged by other users and mark them so that they will no longer be shown
        let signalRConnection = new signalR.HubConnectionBuilder()
            .withUrl("/CommandResponseHub")
            .build();

        signalRConnection.keepAliveIntervalInMilliseconds = 1000 * 60 * 3; // Three minutes
        signalRConnection.serverTimeoutInMilliseconds = 1000 * 60 * 6; // Six minutes

        signalRConnection.on("ReceiveCommandResponse", commandResponse => {            
            if (isResponseForThisIMEI(commandResponse.commandResponse)) {
                updateGrid(state.currentExecutingCommand || currentExecutingCommand.current, commandResponse.isSuccess,
                    !commandResponse.isSuccess, true, commandResponse.commandResponse);

                if (commandResponse.isSuccess)
                    sendCommand();
            }

        });

        signalRConnection.on("ReceiveMessage", msg => {
            console.log(msg);
        });

        signalRConnection.start();

        setState(_state => ({ ..._state, signalRConnection: signalRConnection }));
    }

    const isResponseForThisIMEI = response => {
        if (!response || !props.data.dataItem) return false;
        if (response.includes(props.data.dataItem.imei)) return true;
        return false;
    }

    const checkOrConfigureDevice = e => {
        const _commandGen = CommandGen(state.commands);
        commandGen.current = _commandGen;
        removeFlags();
        setState(_state => ({ ..._state, isInProgress: true, commandGen: _commandGen }));
        sendCommand(_commandGen);
    }

    const removeFlags = () => {

        if (gridRef.current) {
            let gridData = gridRef.current.state.data;
            gridData.forEach(d => {
                d.isInProgress = false;
                d.isSuccess = false;
                d.isFailed = false;
                gridRef.current.updateDataItem(d, 'id');
            });
            
        }
    }

    const setCommandExpireTimer = command => {
        if (commandExpireTimer.current) {
            clearTimeout(commandExpireTimer.current);
        }

        let expireTimer = setTimeout(() => {
            let lastSentCommand = command;
            updateGrid(lastSentCommand, false, true, true);
            setState(_state => ({ ..._state, isInProgress: false }));            
        }, 120000);

        commandExpireTimer.current = expireTimer;
    }

    const sendCommand = (_commandGen) => {
        const commandGenerator = commandGen.current || _commandGen || state.commandGen;
        if (!commandGenerator) return;
        const command = commandGenerator.next();
        
        if (command.done) {
            if (commandExpireTimer.current) {
                clearTimeout(commandExpireTimer.current);
                commandExpireTimer.current = null;
            }

            setState(_state => ({ ..._state, isComplete: true, isInProgress: false, commandExpireTimer: null }));
            return;
        }

        const nextCommand = command.value;
        currentExecutingCommand.current = nextCommand;
        setState(_state => ({ ..._state, currentExecutingCommand: nextCommand }));
        updateGrid(nextCommand);
        axios.post('/api/SMSManager', {
            vehicleId: state.dataItem.vehicleId,
            imei: state.dataItem.imei,
            commandText: state.isConfigure ? nextCommand.configureCommandFormat : nextCommand.readCommandFormat,
            toPhoneNumber: state.dataItem.phoneNumber,
            commandTypeId: nextCommand.commandTypeID
        }).then(response => {
            setCommandExpireTimer(nextCommand);
        }).catch(err => console.log(err));
    }

    const updateGrid = (command, isSuccess, isFailed, isComplete, responseText) => {
        let gridDataItem = gridRef.current.state.data.find(d => d.id == command.id);
        if (gridDataItem) {
            if (!isComplete) gridDataItem.isInProgress = true;
            else gridDataItem.isInProgress = false;
            if (isSuccess) gridDataItem.isSuccess = true;
            if (isFailed) gridDataItem.isFailed = true;
            if (responseText) gridDataItem.commandResponse = responseText;
            gridRef.current.updateDataItem(gridDataItem, 'id');
        }
    }

    const onDataLoaded = data => {
        data.forEach(d => {
            d.isSuccess = false;
            d.commandResponse = '';
            d.isInProgress = false
        });
        setState(_state => ({ ..._state, commands: data }));
    }

    const statusCell = event => {
        if (event.dataItem.isInProgress) return <td><label className="text-waiting"> Waiting for the response </label> <Loader /></td>;
        else if (event.dataItem.isSuccess) return <td> <label className="text-success"> Success </label> </td>;
        else if (event.dataItem.isFailed) return <td> <label className="text-failed"> Failed </label> </td>;
        else return <td> </td>;
    }

    if (!state.dataItem) return null;
    return (
        <div id="configure">
            <PageWrapper title="Configure">
                <div>
                    <div id="topBar">
                        <h3>Vehicle: <b> {state.dataItem.vehicleName} </b> </h3>
                        <div id="content">
                            <div>
                                IMEI: <b>{state.dataItem.imei} </b>
                            </div>
                            <div>
                                Phone Number: <b>{state.dataItem.phoneNumber} </b>
                            </div>
                            <div>
                                Last Seen: <b> {state.dataItem.lastSeen} </b>
                            </div>
                            {!state.isConfigure  && <div>
                                <input type="button" value="Check Device Status" className={"btn btn-primary" + (state.isInProgress ? " disabled" : "")} onClick={checkOrConfigureDevice} />
                            </div>}
                            {state.isConfigure  &&
                                <div>
                                    <input type="button" value="Configure Device" className={"btn btn-primary" + (state.isInProgress? " disabled":"")} onClick={checkOrConfigureDevice} />
                                </div>
                            }
                        </div>
                    </div>
                    <div id="grid">
                        <SMIGrid crudUrl='/api/command?isActive=true' ref={gridRef} onDataLoaded={onDataLoaded} editable={false} filterable={false}>
                            <Column title="Command Type" field="commandType" />
                            {state.isConfigure && <Column title="Command" field="configureCommandFormat" />}
                            {!state.isConfigure && <Column title="Command" field="readCommandFormat" />}
                            <Column title="Response" field="commandResponse" />
                            <Column title="Status" field="status" cell={statusCell} />
                        </SMIGrid>
                    </div>
                </div>
            </PageWrapper>
        </div>
    );
}