import React from 'react';
import viewIcon from '../../images/view.png';
import './PageWrapper.scss';



export default ({ className, pageIcon, title, children }) => {
    if (!className) className = '';   

    return (
        <div className={"page-wrapper " + (className || '')}>
            <div className="title-holder">
                <h1 className="title"><img src={viewIcon} alt="" /><span>{title}</span> </h1>
                <div className="content-holder">
                    {children}
                </div>
            </div>
        </div>
    );
}

