import React from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';

export default (props) => {
    
    return (
        <Dialog title="Change Client" onClose={props.onClose}>
            <div id="client">
                <div>
                    Clients: <DropDownList data={props.data.clients}  dataItemKey="connectionString"
                        textField="name" value={props.data.selectClient} onChange={props.onClientChange} />
                </div>
                <div className="btn">
                    <button className="button" onClick={props.changeConnectionString}>Change</button>
                </div>
            </div>
        </Dialog>
        );
}