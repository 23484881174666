import axios from 'axios';
import React, { Component, Fragment } from 'react';
import {
    Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import './NavMenu.css';
import CompanyImage from '../images/smi_logo.png';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            editingCustomer: null,
            dropdownOpen: false
        };
    }

    componentDidMount() {
        this.getCurrentEditingCustomer();
    }

    getCurrentEditingCustomer = () => {
        axios.get('/api/client/GetCustomerName').then(response => {
            this.setState({ editingCustomer: response.data });
            localStorage.setItem('client', JSON.stringify(response.data));
        }).catch(err => console.log(err));
    }

    logout = e => {
        e.preventDefault();
        localStorage.clear();
        console.log('logout');
        window.location = "/";

    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    toggle = () => {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    render() {
        return (
            <header>

                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/"><img src={CompanyImage} alt="company_logo" /></NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <div className="customer-name">
                            {this.state.editingCustomer &&
                                <Fragment>
                                    <img className="customer-logo" src={this.state.editingCustomer.transitLogoUrl} alt="Client Logo" />
                                    <label>{this.state.editingCustomer.name}</label>
                                </Fragment>
                            }
                        </div>

                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/"><span className="k-icon k-icon-home"></span>Home</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/devices"><span className="k-icon k-icon-device"></span> Devices</NavLink>
                                </NavItem>

                                <Dropdown className="nav-item" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                    <DropdownToggle className="text-dark nav-link" caret>
                                        <span className="k-icon k-i-wrench"></span> Maintain Command
                                        </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>
                                            <NavLink tag={Link} className="text-dark" to="/command-type">Command Type</NavLink>
                                        </DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>
                                            <NavLink tag={Link} className="text-dark" to="/commands">Commands</NavLink>
                                        </DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>
                                            <NavLink tag={Link} className="text-dark" to="/history">Command History</NavLink>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>                                
                                <li className="nav-item">
                                    <a className="text-dark nav-link" onClick={this.props.onClientWindowOpen}><span className="k-icon k-i-track-changes"></span> Client</a>
                                </li>
                                <li className="nav-item">
                                    <a className="text-dark nav-link" onClick={this.logout}><span className="k-icon k-i-logout"></span> Logout</a>
                                </li>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
