import './Clients.scss';

import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Presentation from './Presentation';

export default (props) => {
    const [state, setState] = useState({
        clients: [],
        selectClient: null
    });

    useEffect(() => {
        axios.get('/api/client').then(response => {
            let _client = localStorage.getItem('client');
            let _selectedClient = null;

            if (_client) {
                _client = JSON.parse(_client);
                _selectedClient = response.data.find(c => c.name === _client.name);                
            }
            

            setState({ ...state, clients: response.data, selectClient: _selectedClient });
        }).catch(err => console.log(err));
    }, []);

    const onClientChange = e => {
        setState({ ...state, selectClient: e.target.value });
    }
    const changeConnectionString = e => {
        if (state.selectClient) {
            axios.post('/api/client', { Name: state.selectClient.name, ConnectionString: state.selectClient.connectionString }).
                then(response => {                    
                    window.location.reload();
                }).catch(err => console.log(err));            
        }
    }

    return (
        <Presentation data={state} onClose={ props.onClose} onClientChange={onClientChange} changeConnectionString={changeConnectionString} />
    );
}