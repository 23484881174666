import '../Styles/Home.scss';
import axios from 'axios';
import React, { Component } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import PageWrapper from './PageWrapper/PageWrapper';
import SMIGrid from './SMIGrid/SMIGrid';
import { Link } from 'react-router-dom';

export class Home extends Component {

    state = {
        devices: [],
        isLoading: false
    }
        

    checkStatus = e => {
        console.log(e);
    }

    actionCell = event => {
        return (
            <td>
                <Link to={{
                    pathname: "/configure",
                    state: {
                        dataItem: event.dataItem,
                        isConfigure: true
                    }
                }}>Configure </Link>
                |
                <Link to={{
                    pathname: "/configure",
                    state: {
                        dataItem: event.dataItem,
                        isConfigure: false
                    }
                }}>Check Status</Link>
            </td>
        );
    }
       

    render() {
        return (
            <div id="vehicleStatus">
                <PageWrapper title="Support Vehicle Status">
                    <SMIGrid crudUrl='/api/home' editable={false} pageable={true} itemsPerPage={20} >
                        <Column field="vehicleName" title="Vehicle Name" />
                        <Column field="phoneNumber" title="Phone Number" />
                        <Column field="imei" title="IMEI" />
                        <Column field="hardwareSerialNumber" title="Hardware Serial Number" />
                        <Column field="lastSeen" title="Last Seen" />
                        <Column field="_action" title=" " cell={this.actionCell} filterable={false}/>
                    </SMIGrid>
                </PageWrapper>
            </div>
        );
    }
}
