import $ from 'jquery';
import React, { Component } from 'react';
import { DropDownList as KendoDropDownList } from '@progress/kendo-react-dropdowns';
import { NumericTextBox as KendoNumericTextBox, TextArea, Input } from '@progress/kendo-react-inputs';
import { DatePicker as KendoDatePicker, DateTimePicker as KendoDateTimePicker, TimePicker as KendoTimePicker } from '@progress/kendo-react-dateinputs';
import { CSV as CSVTemplate } from './GridCustomTemplates';
import { Dialog } from '@progress/kendo-react-dialogs';
import FilteredCombobox from './FilteredCombobox';
import { TreeView } from '@progress/kendo-react-treeview';
import { Hint } from "@progress/kendo-react-labels";

export function Dropdown(data, dataValueField, dataTextField, allowNumbersAsString, autoPopupWidth) {
    return class extends Component {

        elementRef = React.createRef();

        handleChange = (e) => {
            let val = e.target.value;
            if (typeof val === 'object') val = val[dataValueField];
            if (!isNaN(val) && val !== '' && !allowNumbersAsString) val = parseInt(val);

            this.props.onChange({
                dataItem: this.props.dataItem,
                field: this.props.field,
                value: val
            });
        }

        componentDidMount() {
            this.elementRef.current._element.focus();
            //this.elementRef.current._element.addEventListener("blur", this.props.exitEdit);
        }

        render() {
            let dataValue = this.props.dataItem[this.props.field];

            let listData = data;
            if (typeof data === 'function') listData = data(this.props.dataItem);

            //If dataValueField was specified, then the dropdown data is an array of objects and we need to pass the matching object to kendo in order for it to be selected
            if (dataValueField && typeof dataValue !== 'undefined' && dataValue !== null && listData) {
                const dataSearchResult = listData.filter(i => i[dataValueField] === dataValue);
                if (dataSearchResult.length > 0) dataValue = dataSearchResult[0];
            }

            let _popupSettings = null;
            if (autoPopupWidth) {
                if (this.props.popupSettings) {
                    this.props.popupSettings.width = 'auto';
                    _popupSettings = this.props.popupSettings;
                } else
                    _popupSettings = { width: 'auto' };
            }


            return (
                <div>
                    <KendoDropDownList ref={this.elementRef} onChange={this.handleChange} value={dataValue} data={listData} textField={dataTextField} onBlur={this.props.exitEdit} dataItemKey={dataValueField} popupSettings={_popupSettings} />
                </div>
            );
        }
    }
}

export function ComboBox(data, dataValueField, dataTextField, autoPopupWidth) {
    return class extends Component {

        allData = typeof data === 'function' ? data(this.props.dataItem) : data.slice()

        state = { data: this.allData }

        elementRef = React.createRef();

        handleChange = (e) => {
            let val = e.target.value;
            if (typeof val === 'object') val = val[dataValueField];
            if (!isNaN(val)) val = parseInt(val);
            this.props.onChange({
                dataItem: this.props.dataItem,
                field: this.props.field,
                value: val
            });
        }

        componentDidMount() {
            this.elementRef.current._element.focus();
            //this.elementRef.current._element.addEventListener("blur", this.props.exitEdit);
        }

        render() {
            let dataValue = this.props.dataItem[this.props.field];

            const listData = this.state.data;

            //If dataValueField was specified, then the dropdown data is an array of objects and we need to pass the matching object to kendo in order for it to be selected
            if (dataValueField && typeof dataValue !== 'undefined' && dataValue !== null) {
                const dataSearchResult = listData.filter(i => i[dataValueField] === dataValue);
                if (dataSearchResult.length > 0) dataValue = dataSearchResult[0];
            }

            return (
                <FilteredCombobox ref={this.elementRef} autoPopupWidth={autoPopupWidth} onChange={this.handleChange} value={dataValue} data={listData} onBlur={this.props.exitEdit} clearButton={false} textField={dataTextField} dataItemKey={dataValueField} />
            );
        }
    }
}

//Pops up a window where the user can select multiple values in a checkbox list. Those values are then stored in the field as a comma-separated string
export function CSV(data, dataValueField, dataTextField, labelRenderFunction, title = '\xa0', dataCaption = null) {
    return class extends Component {

        constructor(props) {
            super(props);
            const dataValue = this.props.dataItem[this.props.field];
            this.state = {
                selectedOptions: dataValue ? ($.isArray(dataValue) ? dataValue : dataValue.split(',')) : [],
                windowVisible: false
            };
        }

        componentDidMount() {
            this.setState({ windowVisible: true });
        }

        checkboxChanged = (e) => {
            const target = e.target;
            if (target.checked && this.state.selectedOptions.indexOf(target.value) === -1) {
                let newSelectedOptions = this.state.selectedOptions.slice(0);
                newSelectedOptions.push(target.value);
                this.setState({ selectedOptions: newSelectedOptions });
            }
            else if (!target.checked) {
                let newSelectedOptions = this.state.selectedOptions.filter(o => o !== target.value);
                this.setState({ selectedOptions: newSelectedOptions });
            }
        }

        setClicked = (e) => {
            const dataValue = this.props.dataItem[this.props.field];
            this.props.onChange({
                dataItem: this.props.dataItem,
                field: this.props.field,
                value: this.state.selectedOptions ? ($.isArray(dataValue) ? this.state.selectedOptions : this.state.selectedOptions.join(',')) : []
            });
            this.props.exitEdit();
        }

        render() {
            let listData = data;
            let buttonCaption = title !== '\xa0' ? title : 'Set';
            if (typeof data === 'function') listData = data(this.props.dataItem);
            const checkboxOptions = listData.map((item, i) => {
                let value = dataValueField ? item[dataValueField] : item;
                if (value) value = value.toString();
                let label = '';
                if (labelRenderFunction) label = labelRenderFunction(item, data);
                else label = dataTextField ? item[dataTextField] : item;
                return <p key={i}><label><input type="checkbox" checked={this.state.selectedOptions.indexOf(value) !== -1} onChange={this.checkboxChanged} value={value} /> {dataTextField ? item[dataTextField] : item}</label></p>
            });

            return (
                <React.Fragment>
                    {CSVTemplate(data, dataValueField, dataTextField)(this.props.dataItem, this.props.field)}
                    {this.state.windowVisible && <Dialog onClose={this.props.exitEdit} title={title}>
                        <div className="csv-update-dialog">
                            {dataCaption && <h3>{dataCaption}</h3>}
                            <div className="checkbox-list">
                                {checkboxOptions}
                            </div>
                            <button className="green" onClick={this.setClicked}>{buttonCaption}</button>
                        </div>
                    </Dialog>}
                </React.Fragment>
            );
        }
    }
}

export function YesNoBinary(inheritanceCallback) {
    const options = [{ val: 1, text: 'Yes' }, { val: 0, text: 'No' }];
    if (inheritanceCallback) {
        const inheritedValue = inheritanceCallback();
        options.unshift({ val: -1, text: 'Default (' + (inheritedValue === 1 ? 'Yes' : 'No') + ')' });
    }
    return Dropdown(options, 'val', 'text');
}

export function NumericTextBox(min = -999, max = 999999999) {
    return class extends Component {

        constructor(props) {
            super(props);
            this.state = { value: props.dataItem[props.field] }
            this.elementRef = React.createRef();
        }

        handleChange = (e) => {
            this.props.onChange({
                dataItem: this.props.dataItem,
                field: this.props.field,
                value: e.value
            });
            this.setState({ value: e.value });
        }

        componentDidMount() {
            this.elementRef.current.focus();
        }

        render() {
            return (
                <KendoNumericTextBox ref={this.elementRef} onChange={this.handleChange} onBlur={this.props.onExit} value={this.state.value} min={min} max={max} />
            );
        }
    }
}

export function DateTimePicker(shortDateFormat, shortTimeFormat) {
    const dateFormat = shortDateFormat + ' ' + shortTimeFormat.replace('tt', 'a');
    return class extends Component {

        handleChange = (e) => {
            this.props.onChange({
                dataItem: this.props.dataItem,
                field: this.props.field,
                value: e.target.value
            });
        }

        componentDidMount() {
            //this.refs.dateTimePicker.widgetInstance.element[0].focus();
            //this.refs.dateTimePicker.widgetInstance.element[0].addEventListener("blur", this.props.exitEdit);
        }

        render() {
            const dataValue = this.props.dataItem[this.props.field];
            return (
                <KendoDateTimePicker onChange={this.handleChange} value={dataValue} format={dateFormat} />
            );
        }
    }
}

export function DatePicker(shortDateFormat, min, max) {
    return class extends Component {

        constructor(props) {
            super(props);
            this.state = { value: props.dataItem[props.field] }
            this.elementRef = React.createRef();
        }

        handleChange = (e) => {
            this.props.onChange({
                dataItem: this.props.dataItem,
                field: this.props.field,
                value: e.value
            });
            this.setState({ value: e.value });
        }

        componentDidMount() {

            this.elementRef.current._element.focus();
            this.elementRef.current._element.addEventListener("blur", this.props.exitEdit);
        }

        render() {
            let today = new Date();
            if (!min)
                min = new Date(today.setFullYear(today.getFullYear() - 3));
            if (!max)
                max = new Date(today.setFullYear(today.getFullYear() + 6));

            return (
                <KendoDatePicker ref={this.elementRef} onChange={this.handleChange} value={this.state.value} min={min} max={max} format={shortDateFormat} />
            );
        }
    }
}

export function TimePicker(shortTimeFormat) {
    const timeFormat = shortTimeFormat.replace('tt', 'a');
    return class extends Component {
        handleChange = (e) => {
            this.props.onChange({
                dataItem: this.props.dataItem,
                field: this.props.field,
                value: e.target.value
            });
        }
        render() {
            const dataValue = this.props.dataItem[this.props.field];
            return (<KendoTimePicker onChange={this.handleChange} value={dataValue} format={timeFormat} />);
        }
    }
}


export function TexboxArea(rows = 1, max = 200) {
    return class extends Component {
        state = {
            value: ""
        }

        componentDidMount() {
            let dataValue = this.props.dataItem[this.props.field];
            this.setState({
                value: dataValue
            });
        }

        handleChange = (e) => {
            this.setState({
                value: e.value
            });
            this.props.onChange({
                dataItem: this.props.dataItem,
                field: this.props.field,
                value: e.value
            });
        }
        render() {
            const dataValue = this.props.dataItem[this.props.field];
            return (
                <React.Fragment>
                    <TextArea onChange={this.handleChange} autoSize={true} style={{ width: '100%' }} rows={rows} maxLength={max} value={dataValue} />
                    <Hint direction={"end"}>
                        {this.state.value.length} / {max}
                    </Hint>
                </React.Fragment>
            );
        }
    }
}

export function MaxLengthTextBox(max) {
    return class extends Component {

        constructor(props) {
            super(props);
            this.state = { value: props.dataItem[props.field], max: max || 300 }
            this.elementRef = React.createRef();
        }

        onLeave = e => {
            let keyCode = e.which || e.keyCode;
            if (keyCode == 9 || keyCode == 13) this.changeData();
            if (keyCode == 13) this.props.exitEdit();
        }

        handleChange = (e) => {
            this.setState({ value: e.value });
        }

        componentDidMount() {
            this.elementRef.current.focus();
        }

        changeData = () => {
            this.props.onChange({
                dataItem: this.props.dataItem,
                field: this.props.field,
                value: this.state.value
            });
        }

        onExit = (e) => {
            this.changeData();            
            this.props.exitEdit();
        }

        render() {
            return (
                <React.Fragment> <Input ref={this.elementRef} width="100%" maxLength={this.state.max} onKeyDown={this.onLeave} autoFocus onBlur={this.onExit} value={this.state.value} onChange={this.handleChange} />
                    <Hint direction={"end"}>
                        {this.state.value.length} / {this.state.max}
                    </Hint>
                </React.Fragment>
            );
        }
    }
}