import React, { useState } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import SMIGrid from '../SMIGrid/SMIGrid';
import * as GridCustomTemplate from '../SMIGrid/GridCustomTemplates';
import * as GridCustomEditors from '../SMIGrid/GridCustomEditors';
import PageWrapper from '../PageWrapper/PageWrapper';
import SMIDeleteColumn from '../SMIGrid/SMIDeleteColumn';

export default (props) => {
    return (
        <PageWrapper title="Commands">
            <SMIGrid crudUrl='/api/command' filterable={false} itemsPerPage={20} pageable={true} >
                <Column field="seqNo" title="Seq#" defaultValue={0} width={ 70} />
                <Column field="commandTypeID" title="Command Type"
                    customTemplate={GridCustomTemplate.Enum(props.commandTypes, 'id', 'commandType')}
                    customEditor={GridCustomEditors.ComboBox(props.commandTypes, 'id','commandType')} />
                <Column field="configureCommandFormat" title="Configure Command" editable={true} />
                <Column field="readCommandFormat" title="Read Command" editable={true}/>
                <Column field="description" title="Description" editable={true} />
                <Column field="isActive" title="Active" width={90} defaultValue={false}
                    customTemplate={GridCustomTemplate.YesNoBinary()}
                    customEditor={GridCustomEditors.YesNoBinary()} />
                <SMIDeleteColumn />
            </SMIGrid>            
        </PageWrapper>
    );
}