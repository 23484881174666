import React, { useRef } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import SMIGrid from '../SMIGrid/SMIGrid';
import * as GridCustomEditors from '../SMIGrid/GridCustomEditors';
import * as GridValidationRules from '../SMIGrid/GridValidationRules';
import SMIDeleteColumn from '../SMIGrid/SMIDeleteColumn';
import PageWrapper from '../PageWrapper/PageWrapper';
import { Link } from 'react-router-dom';


export default (props) => {
    const gridRef = useRef();
    const actionCell = event => {
        return (
            <td>
                <Link className="button button1" to={{
                    pathname: "/configure",
                    state: {
                        dataItem: event.dataItem,
                        isConfigure: true
                    }
                }}>Configure </Link>
            </td>
        );
    }      

    return (
        <div id="devices">
            <PageWrapper title="Devices">
                <SMIGrid ref={gridRef} crudUrl='/api/device' pageable={true} itemsPerPage={20}>
                    <Column field="vehicleId" title="Vehicle ID" width={120} defaultValue={0} filterable={false}                        
                        customEditor={GridCustomEditors.ComboBox(props.data.vehicles, 'vehicleId', 'label', true)} />
                    <Column field="hardwareId" title="Hardware ID" width={100} defaultValue={0}  filterable={false} 
                        customEditor={GridCustomEditors.ComboBox(props.data.hardwares, 'hardwareId', 'registrationNumber', true)} />
                    <Column field="vehicleName" title="Vehicle Name" />
                    <Column field="phoneNumber" title="Phone Number" width={120}
                        validationRules={[GridValidationRules.Unique, GridValidationRules.Required]} />
                    <Column field="sim" title="SIM" width={180} />
                    <Column field="imei" title="IMEI" width={180} validationRules={[GridValidationRules.Unique, GridValidationRules.Required]} />
                    <Column field="serialNumber" title="Hardware Serial Number" width={180} />
                    <Column field="description" title="Note" />
                    <Column field="_f" title=" " cell={actionCell} width={120} filterable={false} />
                    <SMIDeleteColumn />
                </SMIGrid>
            </PageWrapper>
        </div>
    );
}