import React, { useState, useRef } from 'react';

export default (props) => {
    const passwordRef = useRef();
    const [state, setState] = useState({
        username: localStorage.getItem('username') || '',
        password: '',
        rememberUsername: false
    });

    const handleInputChange = (event) => setState({ ...state, [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value })

    const onSubmit = (e) => {
        e.preventDefault();
        if (!props.data.userName) {            
            document.getElementById("userName").focus();
            return;
        }

        if (!props.data.password && passwordRef.current) {            
            document.getElementById("password").focus();
            return;
        }
        props.onSubmit();
    }
    return (
        <div className="login">
            <h1>WELCOME</h1>
            <form className="login-form" onSubmit={onSubmit}>
                <div className="login-container">
                    <div className="content">
                        <div className="username-row">
                            <label htmlFor="username">Username</label>
                            <input tabIndex={1} type="text" id="userName" name="userName" autoFocus value={props.data.userName} onChange={props.handleInputChange} />
                        </div>
                        <div className="password-row">
                            <label htmlFor="password">Password</label>
                            <input tabIndex={2} ref={passwordRef} type="password" id="password" name="password" value={props.data.password} onChange={props.handleInputChange} />
                        </div>
                        <div className="message-row">
                            <label className="login-error">{props.data.errorMessage}</label>
                        </div>
                    </div>
                </div>
                <button tabIndex={5} type="submit" className="green" disabled={props.inProgress ? "disabled" : ""}>Login</button>
            </form>
        </div>
    )
}