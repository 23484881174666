import React, { useState } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import SMIGrid from '../SMIGrid/SMIGrid';
import * as GridCustomTemplate from '../SMIGrid/GridCustomTemplates';
import * as GridCustomEditors from '../SMIGrid/GridCustomEditors';
import LoadingMask from '../LoadingMask';
import PageWrapper from '../PageWrapper/PageWrapper';
import SMIDeleteColumn from '../SMIGrid/SMIDeleteColumn';

export default (props) => {
    return (
        <PageWrapper title="Command Type">
            <SMIGrid crudUrl='/api/commandtype' filterable={false} itemsPerPage={20} pageable={true} >
                <Column field="commandCode" title="Command Code" />
                <Column field="commandType" title="Command Type" />
                <SMIDeleteColumn />
            </SMIGrid>            
        </PageWrapper>
    );
}