import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement);

registerServiceWorker();

axios.interceptors.request.use((config) => {
    var connection = navigator.onLine;
    if (!connection) { return Promise.reject('Request can not be processed because no internet connection is available') };
    var rawUser = localStorage.getItem('user');
    if (rawUser) {
        var user = JSON.parse(localStorage.getItem('user'));
        config.headers.Authorization = `Bearer ${user.authToken}`;        
    }    
    return config;
}, (error) => {
    console.log(error);
    return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
    if (response.status === 401)
        logOut();
    return response;
}, (error) => {
    if (error.response && error.response.status === 401)
        logOut();
    if (error.response && error.response.data && error.response.data.ErrorMessage)
        console.log(error.response.data.ErrorMessage);
    try {
        if (error.response && error.response.data && !error.response.data.ErrorMessage && error.response.data.toLowerCase().search('accessdenied') !== -1) {
            logOut();
        }
        else
            console.log(error.message);
    } catch (e) {
    }
    return Promise.reject(error);
});

function logOut() {
    localStorage.clear();
    window.location = "/";
}

