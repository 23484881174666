import './Styles/custom.css';
import './Styles/kendo-styles.scss';
import '@progress/kendo-theme-default/dist/all.css';

import axios from 'axios';
import React, { Component, Fragment } from 'react';
import Login from './components/Login';
import { Loader } from '@progress/kendo-react-indicators';
import MainPage from './components/MainPage/MainPage';
import LoadingMask from './components/LoadingMask';

export default class App extends Component {
    static displayName = App.name;

    state = {
        isLogin: false,
        isTokenValidating: false
    }

    componentDidMount() {
        var rawUser = localStorage.getItem('user');
        if (rawUser) {
            this.setState({ isTokenValidating: true });
            var user = JSON.parse(localStorage.getItem('user'));
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
                params: {
                    isAccepted: true
                }
            }

            axios.post('/api/Login/ValidateToken', user.authToken, config)
                .then(response => {
                    if (response.data) this.setState({ isLogin: true });
                }).catch(err => console.log(err)).finally(e => this.setState({ isTokenValidating: false }));
        }
        else {
            this.setState({ isLogin:false });
        }
    }

    onLoginSuccess = e => {
        this.setState({ isLogin : true });
    }

    render() {
        return (
            <Fragment>
                {!this.state.isLogin && !this.state.isTokenValidating && <Login onLoginSuccess={this.onLoginSuccess} />}
                {this.state.isLogin && <MainPage />}
                {this.state.isTokenValidating && <LoadingMask />}
            </Fragment>
        );
    }
}
