import React, {useState, Fragment, useEffect } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { DropDownList, ComboBox } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { formatDate } from '@telerik/kendo-intl';
import PageWrapper from '../PageWrapper/PageWrapper';
import SMIGrid from '../SMIGrid/SMIGrid';
import { filterBy } from '@progress/kendo-data-query';

export default (props) => {
    const [vehicles, setVehicles] = useState(props.data.vehicles || []);

    useEffect(() => {
        let _vehicles = props.data.vehicles.slice(0);
        _vehicles.unshift({ vehicleId: -1, vehicleName: 'All' });
        setVehicles(_vehicles);
    }, [props.data.vehicles]);

    const filterData = (filter) => {
        const data = props.data.vehicles.slice();
        return filterBy(data, filter);
    };

    const filterChange = (event) => {
        setVehicles(filterData(event.filter));
    };

    const dateCell = event => {
        return <td> {formatDate(new Date(event.dataItem.commandExecutionDate), 'yyyy-MM-dd hh:mm:ss a')}</td>
    }
    const toolbar = (<Fragment><label style={{ marginRight: 5, fontWeight: 'bold' }}>Select Vehicle </label>
        <ComboBox data={vehicles} dataItemKey="id" filterable={true}
            onFilterChange={filterChange} defaultValue={{ id: -1, vehicleName: 'All' }}
            textField="vehicleName" onChange={props.onVehicleChange} />
    </Fragment>);

    const _crudUrl = `/api/HistoryOBDCommand?vehicleId=${props.data.selectedVehicle ? props.data.selectedVehicle.vehicleId : -1}`;

    return (
        <PageWrapper title="Command History">
            <SMIGrid crudUrl={_crudUrl} editable={false} pageable={true} toolbarButtons={toolbar} itemsPerPage={20}  >
                <Column field="vehicleName" title="Vehicle Name" />
                <Column field="commandType" title="Command Type" />
                <Column field="command" title="Command" />
                <Column field="response" title="Response" />
                <Column field="commandExecutionDate" title="Date" cell={dateCell} />
                <Column field="status" title="Status" />
                <Column field="isAutoConfigure" title="Auto Config"/>
            </SMIGrid>            
        </PageWrapper>
    );
}