import React from 'react';
import Layout from '../Layout';
import Routes from '../Routes';


export default () => {

    return (
        <Layout>
            <Routes />
        </Layout>
        );
}