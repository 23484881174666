import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Presentation from './Presentation';

export default () => {
    const [commandTypes, setCommandTypes] = useState([]);
    useEffect(() => {
        axios.get('/api/commandType').then(response => {
            setCommandTypes(response.data);
        }).catch(err => console.log(err));
    }, []);

    return <Presentation commandTypes={commandTypes} />;
}