import { isValidDate, getISODateWithNoTime } from '../Utils/DateUtil';
// Grid column validation rules that can be passed to the validationRules prop of the SMIGrid component
// Each rule is a function which will be sent the following parameters
//      value - The value to be validated
//      item - The object that makes up the grid row to which "value" belongs
//      field - The field name of the column of the grid to which "value" belongs
//      data - All the data in the grid
// Each function must return true if validation passes or the grid cell being validated will be considered to be in error
// If the function returns a string, that string will be displayed in a tooltip as an error message attached to the cell

export function Required(value) {
    return value && value.toString().replace(/\s/g, '').length > 0 ? true : 'Required Field';
}

export function Unique(value, item, field, data) {
    if (value) {
        for (var i = 0; i < data.length; i++) {
            if (data[i] === item) continue;
            if (!data[i][field]) continue;
            if (isValidDate(data[i][field]) && getISODateWithNoTime(value) == getISODateWithNoTime(data[i][field]))
                return 'Duplicate Value';
            else if (isNaN(value) && data[i][field].trim() == value.trim()) return 'Duplicate Value';
            else if (!isNaN(value) && data[i][field] == value) return 'Duplicate Value';
        }
    }
    return true;
}

export function Length(value, fieldName, maxLength, minLength ) {
    let inputField = fieldName || 'Input';
    minLength = minLength || 0;
    return value && value.toString().replace(/\s/g, '').length <= maxLength ? true : `${inputField} should be ${minLength} to ${maxLength} characters. `
}
