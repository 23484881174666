import React, { useState, useEffect } from 'react';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';

export default React.forwardRef((props, ref) => {

    const [data, setData] = useState(props.data);
    const [filter, setFilter] = useState(null);

    useEffect(() => {
        if (props.data) {
            const allData = props.data.slice(0);
            setData(filterBy(allData, filter));
        }
        else setData([]);
    }, [props.data, filter]);

    
    let _popupSettings = null;
    if (props.popupSettings)
        _popupSettings = props.popupSettings;

    if (props.autoPopupWidth) {
        if (_popupSettings && !_popupSettings.width) {
            _popupSettings.width = 'auto';
        } else            
        _popupSettings = { width: 'auto' };
    }

    return <ComboBox ref={ref} {...props} data={data} filterable={true} popupSettings={_popupSettings} onFilterChange={(e) => setFilter(e.filter)} />
});