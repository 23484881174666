import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Presentation from './Presentation';


export default () => {
    const [state, setState] = useState({
        histories: [],
        vehicles:[],
        selectedVehicle: null,
        isLoading: false
    });

    useEffect(() => {
        axios.get('/api/device').then(response => {
            setState(_state => ({ ..._state, vehicles: response.data }));
        }).catch(err => console.log(err));
    }, []);
    

    const onVehicleChange = e => {
        setState(_state => ({ ..._state, selectedVehicle: e.target.value }));
    }

    return <Presentation data={state} onVehicleChange={onVehicleChange} />;
}