//Workaround to create a custom column component provided by Kendo support
//See: https://www.telerik.com/forums/creating-a-container-gridcolumn-component

import './SMIDeleteColumn.scss';

const SMIDeleteColumn = () => { }
SMIDeleteColumn.displayName = "KendoReactGridColumn";
SMIDeleteColumn.defaultProps = {
    width: "75px",
    title: "Delete",
    field: "_delete",
    filterable: false,
    sortable:false,
    groupable: false
}

export default SMIDeleteColumn;