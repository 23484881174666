import React, { useState, Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import Client from './Client/Clients';

export default (props)=> {
    const[state, setState] = useState({
        showClient:false
    });

    const onClientModalClose = e => {
        setState({ ...state, showClient: false })
    }

    const onClientWindowOpen = e => {
        e.preventDefault();
        setState({ ...state, showClient: true })
    }

    return (
        <div>
            <NavMenu onClientWindowOpen={onClientWindowOpen }/>
            <Container>
                {props.children}
                {state.showClient && <Client onClose={onClientModalClose} />}
            </Container>
        </div>
    );
}

